import dayjs from "dayjs";

export const urlParamsKey = ["domain", "jwt", "redirect", "successRedirect"];
export const getUrlParam = (key) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(key);
};

export const setUrlParam = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const getSessionItem = (key) => {
  return sessionStorage.getItem(key)
    ? JSON.parse(sessionStorage.getItem(key))
    : "";
};

export const getToken = () => {
  return getSessionItem("jwt");
};

export const getDomain = () => {
  return getSessionItem("domain");
};

export const getRedirect = () => {
  return getSessionItem("redirect");
};

export const getSuccessRedirect = () => {
  return getSessionItem("successRedirect");
};

export const toPlay = () => (window.location.href = getSuccessRedirect());

export const toException = () => {
  window.location.href = process.env.VUE_APP_FAIL_BACK_URL;
};

export const useFormatTime = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return "";
  }
  return `${dayjs(startTime).format("YYYY/MM/DD HH:mm")} - ${dayjs(
    endTime
  ).format("HH:mm")}`;
};
