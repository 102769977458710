import "core-js/stable";
import "regenerator-runtime/runtime";
import "@vant/touch-emulator";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/main.css";
import "./utils/permission";

import Vant from "vant";
import "vant/lib/index.less";

Vue.use(Vant);

Vue.config.productionTip = false;

// if (process.env.NODE_ENV === "development") {
//   const { worker } = await import("./mocks/browser");
//   worker.start();
// }

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
