import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "reserve",
    component: () => import("../views/ReservePage.vue"),
    meta: {
      title: "预约考点",
    },
  },
  {
    path: "/view",
    name: "view",
    component: () => import("../views/ReservePage.vue"),
    meta: {
      title: "查看考点",
    },
  },
  {
    path: "/modify",
    name: "modify",
    component: () => import("../views/ReservePage.vue"),
    meta: {
      title: "修改考点",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/RoomInfo.vue"),
    meta: {
      title: "我的预约",
    },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("../views/MaintenancePage.vue"),
    meta: {
      title: "服务维护",
    },
  },
  {
    path: "/print",
    name: "print",
    component: () => import("../views/PrintPage.vue"),
    meta: {
      title: "打印准考证",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
