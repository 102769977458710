import Router from "@/router";
import {
  getRedirect,
  setUrlParam,
  toException,
  urlParamsKey,
} from "@/utils/index";
import { Toast } from "vant";

const routerNames = [
  "reserve",
  "about",
  "print",
  "view",
  "maintenance",
  "modify",
];

Router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // if (process.env.NODE_ENV === "development") {
  //   next();
  //   return;
  // }

  const hasParams = urlParamsKey.every((key) =>
    Object.keys(to.query).includes(key)
  );
  const hasUrlValues =
    hasParams && Object.values(to.query).every((value) => !!value);

  // 是否有url参数
  if (hasParams && hasUrlValues) {
    // 设置url参数到sessionStorage
    urlParamsKey.forEach((key) => {
      setUrlParam(key, to.query[key]);
    });

    // 获取路由地址
    const redirect = getRedirect();
    // 如果有路由地址，跳转到路由地址 否则跳转到APP菜单
    if (routerNames.includes(redirect)) {
      to.name === redirect ? next() : next({ name: redirect, query: to.query });
    } else {
      Toast({
        message: "路由地址错误",
        type: "fail",
      });
      toException();
      // next(false);
    }
  }
  // 如果没有url参数，或者不全，直接返回APP菜单
  else {
    Toast({
      message: "参数错误",
      type: "fail",
    });
    toException();
    // next(false);
  }
});
